import { Button, Form, Tooltip } from "antd";
import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { edAttrField } from "../../EdAttribute";
import { EdObjStates } from "../../EdObject";
import { PermissionsCopyType, ZPermissions } from "../roleTypes";

interface PropsCopyOnStatesButton {
  stateName: string;
  stateId: number;
  copyType: PermissionsCopyType;
}

export const CopyOnStatesButton: React.FC<PropsCopyOnStatesButton> = ({
  stateName,
  stateId,
  copyType,
}) => {
  const form = Form.useFormInstance();
  const rolesMap: EdObjStates | undefined = Form.useWatch(edAttrField.rolesMap);
  const curPermissonsItem: ZPermissions | undefined = Form.useWatch([
    edAttrField.rolesMap,
    String(stateId),
  ]);
  const copyOnStates = () => {
    if (!rolesMap || !curPermissonsItem) return;
    const newValue = Object.fromEntries(
      Object.entries(rolesMap).map(([key]) => [key, curPermissonsItem]),
    );
    form.setFieldValue(edAttrField.rolesMap, newValue);
  };

  return (
    <Tooltip
      title={`Скопировать права из состояния ${stateName} на все состояния ${copyType === PermissionsCopyType.attribute ? "атрибута" : "объекта"}`}
    >
      <Button
        size="small"
        type="text"
        icon={<CopyOutlined />}
        onClick={(event) => {
          event.stopPropagation();
          copyOnStates();
        }}
      />
    </Tooltip>
  );
};
