import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { ZEntity, ZEntityField } from "src/types/ZEntity";
import {
  FormBlockDef,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { ZIdName } from "src/types/ZIdName";
import { getIdNames } from "src/references/getIdNames";
import { Permission, hasPermissionIn } from "src/types/Permission";
import { FormInstance } from "antd";
import { ZObjState } from "src/types/ZObjState";
import { ifDef } from "src/common/ifDef";
import { ZObjectItem } from "src/types/ZObjectItem";
import { getValidStateId } from "src/common/getValidStateId";
import { AnchorItem } from "src/common/anchorItem";
import { t } from "i18next";
import { emmitLastObjectUpdateSE } from "src/common/storageEventUtils";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { loadServicesForEntity } from "src/businessServices/apiBusinessServices";
import { getEntityName } from "src/common/getEntityName";
import { makeDictionary } from "src/common/makeDictionary";
import { onError } from "src/common/onError";
import { getFormulaDepAttsIds } from "src/common/attrEdit/components/ZAttrFormula";
import { buildMainBlock } from "./blockBuilder/buildMainBlock";
import {
  EdCardValues,
  calculateEntityFormulas,
  callChangeEntityState,
  createEntitiesByIterator,
  createEntity,
  deleteEntity,
  ed2entity,
  entity2ed,
  loadAvailableStates,
  loadEntity,
  loadObject,
  saveEntity,
} from "./apiEntityCard";
import { ZEntityAvailableState } from "./EntityPage.types";
import { loadObjectStates } from "../ManagementPage/Obj2Tab/roles/rolesApi";
import { anchorList } from "./EntityFormWithAnchor/anchorList";
import { EntityHistoryStore } from "./EntityHistory/EntityHistoryStore";
import { hasIterator } from "../ManagementPage/objIterator";
import { getObjectAttsFlat } from "./getObjectAttsFlat";

const initData2InitEnt = (data: Record<string, string[]>): ZEntity =>
  ({
    attributeValues: Object.entries(data).map(([attrId, values]) => ({
      attributeId: Number(attrId),
      values,
    })),
  }) as ZEntity;

const validateInitialData = (
  obj: ZObjectItem,
  initialData: Record<string, string[]>,
): Promise<Record<string, string[]>> =>
  new Promise((resolve, reject) => {
    const objAtts = getObjectAttsFlat(obj);
    const initialKeys = Object.keys(initialData);
    const objKeySet = new Set(objAtts.map((attr) => String(attr.id)));
    const hasWrong = initialKeys.find((key) => !objKeySet.has(key));
    if (hasWrong) {
      reject(Error(`Wrong initial attribute with id = "${hasWrong}"`));
    } else {
      resolve(initialData);
    }
  });

export type EntityCardData = {
  object: ZObjectItem; // Метаданные - описание объекта
  entity?: ZEntity; // // Данные - описание сущности
  availableStates?: ZEntityAvailableState[];
  states?: ZObjState[];
  services?: ObjectServiceExt[];
};

export type EntityCardDataExt = EntityCardData & {
  typesMap: Record<number, string>;
};

export const entityCardFormName = "EntityForm";

export class EntityCardStore {
  constructor(fnCreate?: (entity: ZEntity) => Promise<ZEntity>) {
    this.onCreate = fnCreate ?? createEntity;
    makeAutoObservable(this);
  }

  private onCreate: (entity: ZEntity) => Promise<ZEntity>;

  info: RemoteData<EntityCardData> = { status: "none" };

  setInfo(info: RemoteData<EntityCardData>) {
    this.info = info;
  }

  cvtEntity(entity?: ZEntity): EdCardValues {
    if (!entity) return {};
    const { info } = this;
    if (info.status !== "ready") return {};
    return entity2ed(entity /* , info.result.object, this.attrTypesMap */);
  }

  get initialData(): EdCardValues {
    const { info } = this;
    return info.status === "ready" ? this.cvtEntity(info.result.entity) : {};
  }

  get availableStates(): ZEntityAvailableState[] | undefined {
    const { info } = this;
    return info.status === "ready" ? info.result.availableStates : undefined;
  }

  get services(): ObjectServiceExt[] {
    const { info } = this;
    return (info.status === "ready" ? info.result.services : undefined) ?? [];
  }

  attrTypes: ZIdName[] = [];

  setAttrTypes(types: ZIdName[]) {
    this.attrTypes = types;
  }

  get attrTypesMap(): Record<number, string> {
    return this.attrTypes.reduce(
      (acc, { id, name }) => ({ ...acc, [id]: name }),
      {} as Record<number, string>,
    );
  }

  get attrNamesMap(): Record<number, string> | undefined {
    if (this.info.status !== "ready") return undefined;
    return this.info.result.object.attributes.reduce(
      (acc, { id, name }) => ({ ...acc, [id]: name }),
      {} as Record<number, string>,
    );
  }

  get objectId(): number | undefined {
    if (this.info.status !== "ready") return undefined;
    return this.info.result.object.id;
  }

  get canEntityUpdate(): boolean {
    const { info } = this;
    if (info.status !== "ready") return false;
    const { entity } = info.result;
    if (!entity) return true;
    return hasPermissionIn(entity, Permission.entityUpdate);
  }

  get entityName(): string {
    if (this.info.status === "error") {
      return t("Error");
    }
    if (this.info.status === "ready") {
      const { object, entity } = this.info.result;
      if (!entity?.id)
        return t("New instance of object", { name: object.name });

      return this.loadedEntityName;
    }
    return "";
  }

  loadedEntityName = "";

  setLoadedEntityName(name: string) {
    this.loadedEntityName = name;
  }

  async loadEntityName(object: ZObjectItem, entity: ZEntity) {
    try {
      this.setLoadedEntityName("...");
      this.setLoadedEntityName(
        await getEntityName({
          object,
          entity,
          level: 0,
          typesMap: this.attrTypesMap || {},
        }),
      );
    } catch (e) {
      this.setLoadedEntityName("ERROR");
    }
  }

  get objectName(): string {
    if (this.info.status === "error") {
      return t("Error");
    }
    if (this.info.status === "ready") {
      const { object } = this.info.result;
      return String(object.name);
    }
    return "";
  }

  formStore = new FormWithBlockStore();

  get isCreateWithIterator(): boolean {
    const { info } = this;
    if (info.status !== "ready") return false;
    const { object } = info.result;
    return hasIterator(object);
  }

  async commonInit() {
    this.setAttrTypes(await getIdNames("attrType"));
  }

  async initNew(objectId: number | string, initial?: Record<string, string[]>) {
    try {
      this.setInfo({ status: "wait" });
      const [object] = await Promise.all([
        loadObject(objectId, {
          stateId: getValidStateId(undefined),
          translate: true,
        }),
        this.commonInit(),
      ]);

      const safeInitial = await validateInitialData(object, initial || {});

      this.setInfo({
        status: "ready",
        result: {
          object,
          entity: initData2InitEnt(safeInitial),
        },
      });
    } catch (error) {
      this.setInfo({ status: "error", error });
    }
  }

  async init(entityId: string) {
    try {
      this.setInfo({ status: "wait" });
      await this.commonInit();
      // Здесь пока не ясно, нужен перевод или нет.
      // Основная проблема - переводится всё что нужно и не нужно.
      // Например, вместо "true" может приехать что угодно - "истина", "真的" или что-то ещё.
      // Но это сразу нарушает работу контроллера. И пользователь видит неправильные данные
      const entity = await loadEntity(entityId, { translate: false });
      const { stateId, objectId } = entity;
      const object = await loadObject(objectId, {
        stateId: getValidStateId(entity.stateId),
        translate: true,
      });
      const states = await loadObjectStates(objectId, { translate: true });
      const availableStates = stateId
        ? await loadAvailableStates(objectId, stateId, { translate: true })
        : undefined;
      const services = await loadServicesForEntity(objectId, entity.id, {
        translate: true,
      });
      this.setInfo({
        status: "ready",
        result: { object, entity, availableStates, states, services },
      });
      this.loadEntityName(object, entity);
    } catch (error) {
      this.setInfo({ status: "error", error });
    }
  }

  get stateNames(): Record<number, string> {
    const res: Record<number, string> = {};
    if (this.info.status === "ready") {
      this.info.result.states?.forEach(({ id, name }) => {
        res[id] = name;
      });
    }
    return res;
  }

  get curStateName(): string {
    const { info } = this;
    if (info.status === "ready") {
      return (
        ifDef(
          info.result.entity?.stateId,
          (stateId) => this.stateNames[stateId] ?? `${stateId}`,
        ) ?? ""
      );
    }
    return "";
  }

  get buzy(): boolean {
    return this.formStore.saving || this.deleting;
  }

  get documentTitle(): string {
    if (this.info.status === "ready") {
      return this.entityName;
    }
    return "";
  }

  async changeState(stateId: number | string, form: FormInstance) {
    try {
      this.formStore.setSaving(true);
      const { info } = this;
      if (info.status !== "ready") throw Error(t("Data not loaded"));
      const { entity } = info.result;
      if (!entity) throw Error(t("The instance is missing"));
      // Проверка полей
      await form.validateFields();
      // Сохранение
      await this.save(form.getFieldsValue());
      // Переключение состояния
      await callChangeEntityState(entity.id, +stateId);
      await this.init(String(entity.id));
    } catch (e) {
      if ("errorFields" in e) {
        // Если ошибка валидации формы, то попытаться установить фокус на ошибочное поле
        if (Array.isArray(e.errorFields)) {
          const name = e.errorFields[0]?.name;
          if (name && this.rootBlock) {
            this.formStore.activate(entityCardFormName, name, this.rootBlock);
          }
        }
        throw Error(
          t("It is required to correctly fill in the fields on the form"),
        );
      } else {
        throw e;
      }
    } finally {
      this.formStore.setSaving(false);
    }
  }

  async create(values: EdCardValues): Promise<EdCardValues> {
    // После выполнения запросв будет редирект на новую страницу с entityCard/:id
    // Там данные стора загрузятся заново. Поэтому нет смысла их сейчас синхронизировать.
    const { info } = this;
    if (info.status === "ready") {
      const srcEntity = ed2entity(values, 0, info.result.object.id);
      if (this.isCreateWithIterator) {
        const res = await createEntitiesByIterator(srcEntity);
        return res as unknown as EdCardValues; // это конечно нехороший костыль, но кто же мог предполагать что сабмит формы может возвращать массив...
      }
      const resEntity = await this.onCreate(srcEntity);
      return {
        ...this.cvtEntity(resEntity),
        // Небольшой костыль. Т.к для редиректа нужен id сущности, который не является частью формы
        entityId: String(resEntity.id),
      };
    }
    return Promise.reject(
      Error(
        t("Saving is not possible. Status: {{status}}", {
          status: info.status,
        }),
      ),
    );
  }

  async save(values: EdCardValues): Promise<EdCardValues> {
    const { info } = this;
    if (info.status === "ready") {
      const entityId = info.result.entity?.id;
      if (!entityId) throw Error("Cant save empty entity");
      const srcEntity = ed2entity(values, entityId, info.result.object.id);
      const resEntity = await saveEntity(srcEntity);
      this.internalUpdate(resEntity);
      emmitLastObjectUpdateSE(this.objectId);
      this.historyStore?.reloadHistory();
      return this.cvtEntity(resEntity);
    }
    return Promise.reject(
      Error(
        t("Saving is not possible. Status: {{status}}", {
          status: info.status,
        }),
      ),
    );
  }

  async onFormValuesChange(
    form: FormInstance,
    changedValues: EdCardValues,
    values: EdCardValues,
  ) {
    const recalcFormulas = async () => {
      const { info } = this;
      if (info.status === "ready") {
        const { entity, object } = info.result;
        if (!entity) throw Error("Cannot recalculate empty entity");
        /**
         * если измененный атрибут входит входит в формулу, то вызываем пересчет
         */
        const depAttsKeys = new Set(
          getFormulaDepAttsIds(object, this.attrTypesMap),
        );
        const needRecalc = Object.keys(changedValues).find((attrId) =>
          depAttsKeys.has(attrId),
        );
        if (!needRecalc) return;
        /**
         * на бэке сказали, что слать надо все атрибуты разом
         * поэтому берем все values, а не changedValues
         */
        const formEntity = ed2entity(values, entity.id, object.id);
        const resCalc = await calculateEntityFormulas(formEntity);
        const makeAttrDict = (attrList: ZEntityField[]) =>
          makeDictionary(attrList, ({ attributeId }) => attributeId);
        const finalAtts = Object.values({
          ...makeAttrDict(formEntity.attributeValues),
          ...makeAttrDict(resCalc),
        });
        const currEnt: ZEntity = { ...entity, attributeValues: finalAtts };
        currEnt.attributeValues = finalAtts;
        form.setFieldsValue(this.cvtEntity(currEnt));
      }
    };
    try {
      await recalcFormulas();
      await this.copyByValue(form, changedValues);
    } catch (error) {
      onError(error);
    }
  }

  async copyByValue(form: FormInstance, changedValues: EdCardValues) {
    const { info } = this;
    if (info.status !== "ready") return;
    const { object, entity } = info.result;
    // Функция должна срабатывать, только если текущий экземпляр - новый
    if (entity?.id) return;

    // Находим ссылку на другой объект, значения из атрибутов которого будем подставлять
    const changed = Object.entries(changedValues);
    if (changed.length !== 1) return;
    const [triggerAttrId, triggerValue] = changed[0] || [];
    // Важно, чтобы у ссылки не было возможности множественного выбора
    // Это доп проверка, так как в настройка нельзя выбрать ссылку с множ выбором
    if (!triggerAttrId || !triggerValue || triggerValue.length !== 1) return;

    // Находим атрибуты, в которые хотим подставить значения из экземпляра ссылки
    const recalcAttrIds = new Set(
      getObjectAttsFlat(object)
        .filter(
          ({ linkAttributeId, parentAttributeId }) =>
            String(linkAttributeId) === triggerAttrId && !!parentAttributeId,
        )
        .map(({ id, parentAttributeId }) => ({
          id,
          parentAttributeId,
        })),
    );
    const linkedEntityId = triggerValue?.[0];

    if (!recalcAttrIds.size || !linkedEntityId) return;

    // Находим новые значения атрибутов в эземпляре из ссылки для подстановки в целевые атрибуты
    const linkedEntity = await loadEntity(linkedEntityId);
    const newValues = new Map(
      linkedEntity.attributeValues.map(({ attributeId, values: vals }) => [
        attributeId,
        vals,
      ]),
    );

    // Подставляем найденные значения в целевые атрибуты
    recalcAttrIds.forEach(({ id, parentAttributeId }) => {
      const newValue = newValues.get(parentAttributeId!);
      form.setFieldValue(String(id), newValue);
    });
  }

  internalUpdate(newEntity: ZEntity) {
    if (this.info.status === "ready") {
      this.info.result.entity = newEntity;
    }
  }

  get rootBlock(): FormBlockDef | null {
    if (this.info.status === "ready") {
      return buildMainBlock(this.info.result.object, {
        typesMap: this.attrTypesMap,
        canUpdate: this.canEntityUpdate,
        stateId: this.info.result.entity?.stateId,
        entityId: this.info.result.entity?.id,
      });
    }
    return null;
  }

  get anchors(): AnchorItem[] {
    return anchorList(this.rootBlock);
  }

  // При вызове этой функции нужно не забывать делать catch()
  async doDelete() {
    this.setDeleting(true);
    try {
      const { info } = this;
      if (info.status !== "ready" || !info.result.entity)
        throw Error(t("Deletion is impossible"));
      await deleteEntity(info.result.entity.id);
      emmitLastObjectUpdateSE(this.objectId);
    } finally {
      this.setDeleting(false);
    }
  }

  get availableDelete(): boolean {
    if (this.info.status !== "ready") return false;
    const { entity } = this.info.result;
    if (!entity) return false;
    return hasPermissionIn(entity, Permission.entityDelete);
  }

  deleting = false;

  setDeleting(value: boolean) {
    this.deleting = value;
  }

  get entityId(): number | null {
    if (this.info.status !== "ready") return null;
    return this.info.result.entity?.id ?? null;
  }

  get historyStore(): EntityHistoryStore | null {
    return this.entityId && this.stateNames
      ? new EntityHistoryStore(
          this.entityId,
          this.stateNames,
          this.curStateName,
          this.entityName,
        )
      : null;
  }

  // eslint-disable-next-line class-methods-use-this
  get textOfCreateButton(): string {
    // На самом деле, деййствия разные, в зависимости от this.isCreateWithIterator
    // Но LPLM-1254
    return t("Create");
  }
}

export const entityCardStore = new EntityCardStore();
