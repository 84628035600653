import { z } from "zod";
import { ZAttribute } from "src/types/ZAttribute";
import { ZAttrEditInfo } from "src/common/attrEdit";
import { BuilderCtxTable } from "src/pages/EntityFiltersPage/EntityList/BuilderCtxTable";
import { ZAttrViewInfo } from "../zAttrViewInfo";

export const zChildEntitiesCellInfo = z.object({
  view: z.literal("ChildEntitiesCellInfo"),
  labelAtts: z.string().array(),
});

export type ZChildEntitiesCellInfo = z.infer<typeof zChildEntitiesCellInfo>;

export const makeChildEntitiesCellInfoProps = (
  attribute: ZAttribute,
  viewInfo: ZAttrViewInfo | undefined,
  editorInfo: ZAttrEditInfo | undefined,
  ctx?: BuilderCtxTable,
) => ({ attribute, viewInfo, editorInfo, ctx });
