import { Button, Form, Tooltip } from "antd";
import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { edAttrField } from "../../EdAttribute";
import { EdObjStates } from "../../EdObject";
import { ZPermissions } from "../roleTypes";
import { createCopyOnRoleMap } from "./createCopyOnRoleMap";

interface PropsCopyOnRoleButton {
  stateId: number;
  roleName: string;
  groupId: number;
  roleId: number;
}

export const CopyOnRoleButton: React.FC<PropsCopyOnRoleButton> = ({
  stateId,
  roleName,
  roleId,
  groupId,
}) => {
  const form = Form.useFormInstance();
  const rolesMap: EdObjStates | undefined = Form.useWatch(edAttrField.rolesMap);
  const curPermissonsItem: ZPermissions | undefined = Form.useWatch([
    edAttrField.rolesMap,
    String(stateId),
  ]);
  const copyOnRole = (curGroupId: number, curRoleId: number) => {
    if (!rolesMap || !curPermissonsItem) return;
    const newValue = createCopyOnRoleMap({
      curGroupId,
      curRoleId,
      curPermissonsItem,
      rolesMap,
    });
    if (!newValue) return;
    form.setFieldValue(edAttrField.rolesMap, newValue);
  };

  return (
    <Tooltip
      title={`Скопировать на роль ${roleName} для всех состояний атрибута`}
    >
      <Button
        size="small"
        type="text"
        icon={<CopyOutlined />}
        onClick={() => copyOnRole(groupId, roleId)}
      />
    </Tooltip>
  );
};
