import { getEditorInfo } from "src/common/attrEdit";
import { ZAttribute } from "src/types/ZAttribute";
import { getComponentEditor } from "src/pages/EntityCardPage/blockBuilder/createItem2";

export const composeLabelsWithEditConfig = (
  attr: ZAttribute,
  values: string[],
  typesMap: Record<number, string>,
): string[] => composeLabel(attr, values, typesMap);

const joinAddonAfter = (values: string[], addonAfter?: string) =>
  values?.map((v) => (addonAfter ? `${v} ${addonAfter}` : v));
const alignNumber = (values: string[], precision?: number) =>
  values?.map((v) => {
    const vNum = Number(v);
    const canAlign = !!precision && !Number.isNaN(vNum);
    return canAlign ? vNum.toFixed(precision) : v;
  });

const composeLabel = (
  attr: ZAttribute,
  values: string[],
  typesMap: Record<number, string>,
): string[] => {
  const editorInfo = getEditorInfo(attr.viewStyles);
  const component = getComponentEditor(editorInfo, typesMap, attr);
  if (!component) return values;
  switch (component.editor) {
    case "InputNumber":
      return joinAddonAfter(
        alignNumber(values, component.precision ?? undefined),
        component.addonAfter ?? undefined,
      );
    case "Formula":
      return joinAddonAfter(
        alignNumber(values, component.precision ?? undefined),
        component.addonAfter || "",
      );
    case "Input":
      return joinAddonAfter(values, component.addonAfter ?? undefined);
    case "MaskedInput":
      return joinAddonAfter(values, component.addonAfter ?? undefined);
    default:
      return values;
  }
};
